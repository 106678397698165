<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="content">
        <div class="form">
          <div class="item">
            <img src="@/assets/pass.png" class="icon" />
            <input type="number" oninput="if(value.length > 4)value = value.slice(0, 4)" v-model="password" class="input" placeholder="请输入4位数字" />
          </div>
        </div>
        <div class="button primary mt" @click="submit">提交</div>
        <div class="button1 primary1 mt1" @click="clear">清空/重置</div>
      </div>
      <div class="xg">  
        <div class="textxg1">重要提示</div>   
        <div class="textxg">初始状态为管理员审批方式。</div>
        <div class="textxg">设置密码提交后，人员上岗需使用此密码。</div>   
        <div class="textxg">清空密码提交后，变回管理员审批方式。</div>   
      </div>
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash-es';
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  async created() {
    let { data } = await this.axios.get('/ctid/tlService/getmm');
    if (data.flag === '0') {
      this.password = data.data;
    }    
  },
  data() {
    return {
      password: ''
    };
  },
  methods: {
    async submit() {

      if(isEmpty(this.password)) {
        Dialog.confirm({
          message: '密码清空后，上岗需管理员审批！'         
        }).then(async () =>{
          let { data } = await this.axios.post(
            '/ctid/tlService/setxgmm',
            qs.stringify({ pw: this.password})
          );
          /*if (data.flag === '0') {
            Dialog({ message: data.message });
          } else {
            Dialog({ message: data.message });
          }*/
          //Dialog({ message: data.message });
          Toast(data.message);
          this.$router.push('/position/list');
        });
      }else{
        Dialog.confirm({
          message: '密码修改后，上岗将使用此密码！'
        }).then(async () =>{
          //this.$router.push('/position/bind_post'); 
          let { data } = await this.axios.post(
            '/ctid/tlService/setxgmm',
            qs.stringify({ pw: this.password})
          );
          /*if (data.flag === '0') {
            Dialog({ message: data.message });
          } else {
            Dialog({ message: data.message });
          }*/
          Toast(data.message);
          this.$router.push('/position/list');
        });     
      }
      
      //this.$router.replace('/position/change_password');
      /*if (isEmpty(this.password)) {
        Toast('密码不可以为空!');
        return;
      }

      let { data } = await this.axios.post(
        '/ctid/tlService/addUserMW',
        qs.stringify({ username: this.username, usertel: this.tel })
      );
      if (data.flag === '0') {
        Toast('提交成功!');
        if (data.data === '1') // 1- 游客
          this.$router.replace('/user/my');
        else // 0 - 管理员
          this.$router.replace('/admin/tel?tel=' + this.tel);
      } else {
        Dialog({ message: data.message });
      }*/
    },
    clear() {
      this.password = '';
    }
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 229px;
}
.content {
  margin-top: 60px;
}
.xg {
  width: 600px;
  overflow: hidden;
  margin: 0px auto;
  padding: 80px 0px 20px 0px;
  .textxg1 {
    width: 300px;
    height: 80px;
    display: block;
    text-align: center;
    margin: 0px auto;
    color: #b80808;
    font-size: 28px;
  }
  .textxg {
    width: 532px;
    height: 50px;
    display: block;
    margin: 0px auto;
    font-size: 28px;
  }
}
.button1 {
  width: 100%;
  height: 88px;
  text-align: center;
  overflow: hidden;
  font-size: 32px;
  line-height: 88px;
  color: #000000;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;
  cursor: pointer;
  &.primary1 {
    background: linear-gradient(90deg, rgba(92, 197, 216, 1) 0%, rgba(12, 149, 173, 1) 100%);
    color: #fff;
  }
  &.mt1 {
    margin-top: 15px;
  }
}
</style>